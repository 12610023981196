import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { Paragraph3 } from '../reusables/textElements';
import { breakpoints, colors } from '../../lib/constants';
import Button from '../reusables/Button';
import ContentWrapper from '../reusables/ContentWrapper';
import brainImage from '../../assets/ikoner-01.svg';
import classesImage from '../../assets/ikoner-04.svg';
import nudgingImage from '../../assets/ikoner-02.svg';
import workshopsImage from '../../assets/ikoner-03.svg';

const Heading = styled.h2({
  fontSize: 48,
  lineHeight: 1.1,
  textAlign: 'center',
  color: colors.baseBlue,
  textTransform: 'uppercase',
  margin: '0 auto',
  marginTop: 60,
  marginBottom: 60,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    fontSize: 36,
    maxWidth: 300,
    marginTop: 30,
    marginBottom: 40,
  },
});

const Items = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  justifyContent: 'center',
  alignItems: 'start',
  margin: '0 auto',
  marginBottom: 80,
  maxWidth: 1000,
  [`@media (max-width: 900px)`]: {
    gridTemplateColumns: '1fr 1fr',
  },
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    gridTemplateColumns: '1fr',
    marginBottom: 40,
  },
});

const Item = styled(Link)({
  color: colors.baseBlue,
  fontSize: 24,
  padding: '1rem',
  // fontFamily: fonts.bold,
  textAlign: 'center',
  [`@media (max-width: 600px)`]: {
    fontSize: 14,
  },
  h4: {
    marginBottom: 15,
  },
});

const ImgWrapper = styled.div({
  display: 'inline-block',
  width: 200,
  height: 180,
  [`@media (max-width: 600px)`]: {
    width: 100,
    height: 85,
  },
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    width: 180,
  },
});

const Img = styled.img({
  width: '100%',
});

const WhatButtons = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  flex: 1,
  margin: '0 auto',
  [`@media (max-width: 600px)`]: {
    flexDirection: 'column',
    maxWidth: '270px',
  },
});

const WhatContainer = styled.div({
  backgroundColor: 'rgb(255, 143, 223)',
});

export default ({ children }) => (
  <WhatContainer>
    <ContentWrapper>
      <Heading>Det här kan vi göra för dig</Heading>
      <Items>
        <Item to="/about">
          <ImgWrapper>
            <Img alt="Beteendestrategi-ikon" src={brainImage} />
          </ImgWrapper>
          <h4>Beteendestrategi</h4>
          <Paragraph3>
            Med tvärdisciplinära metoder designar vi träffsäkra insatser för
            både privat och offentlig sektor.
          </Paragraph3>
        </Item>
        <Item to="/about">
          <ImgWrapper>
            <Img alt="Beteendeinsikt-ikon" src={nudgingImage} />
          </ImgWrapper>
          <h4>Beteendeinsikt</h4>
          <Paragraph3>
            Vi skapar praktisk beteendedesign för olika beslutsmiljöer, alltid
            med användarens behov i centrum.
          </Paragraph3>
        </Item>
        <Item to="/about">
          <ImgWrapper>
            <Img alt="Nudging-ikon" src={workshopsImage} />
          </ImgWrapper>
          <h4>Beteendesign & nudging</h4>
          <Paragraph3>
            Beteendedesign är ett paraplybegrepp där nudging är ett av flera
            effektiva, beteendeförändrande verktyg.
          </Paragraph3>
        </Item>
        <Item to="/about">
          <ImgWrapper>
            <Img alt="Utbildning-ikon" src={classesImage} />
          </ImgWrapper>
          <h4>Utbildning & föreläsning</h4>
          <Paragraph3>
            Vi faciliterar regelbundet egna kurser och workshops och skräddarsyr
            gärna utbildningskoncept på förfrågan.
          </Paragraph3>
        </Item>
      </Items>
      <WhatButtons>
        <Button css={{ margin: 10 }} as={Link} to="/about">
          Läs mer om våra tjänster
        </Button>
        <Button variant="filled" css={{ margin: 10 }} as={Link} to="/contact">
          Kontakta oss
        </Button>
      </WhatButtons>
    </ContentWrapper>
    {children}
  </WhatContainer>
);
